export const Versi = "1.8.91"

// App Mode
export let MODE = 'PRODUCTION';
// export let MODE = 'DEVELOPMENT';
// export let MODE = 'STAGING';

// Api Version
export let API_VERSION = 'V2'; //MySql
// export let API_VERSION = 'V4'; //DEV PostgreSql
// export let API_VERSION = 'STAGING'; //PostgreSql

export const MAIN_HOST =
    MODE === 'PRODUCTION'
        ? 'https://belanjapasti.com/'
        : API_VERSION === 'V2'
            ? 'https://api-v2.belanjapasti.com/'
            : MODE === 'STAGING'
                ? 'https://stag.belanjapasti.com/'
                : 'http://103.107.85.78:8092/';

export const MEDIA_HOST =
    MODE === 'PRODUCTION' || MODE === 'STAGING'
        ? 'https://s3.belanjapasti.com/'
        : 'https://s3.belanjatech.com/';

export const API =
    MAIN_HOST +
    (MODE === 'PRODUCTION'
        ? 'apps-services/backend/'
        : API_VERSION === 'V2'
            ? 'apps-services/backend/'
            : API_VERSION === 'STAGING'
                ? 'apps-services/backend/'
                : 'backend/');

export const ImageEx = MEDIA_HOST + 'media/image/customer_wl/'

export const IMG_HOST = MEDIA_HOST + 'media/image/'
export const KTP_HOST = MEDIA_HOST + 'media/ktp/'
export const SELF_HOST = MEDIA_HOST + 'media/selfie/'
export const THUMB_HOST = MEDIA_HOST + 'media/image/thumb/'
export const DEFAULT_IMG = MEDIA_HOST + 'media/image/default-img.png'
export const MAP_KEY = 'AIzaSyDMMrJOR3FT_lFG1JqQ8O1LcqCfKlHxhlo'

