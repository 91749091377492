import {MAIN_HOST} from "../config/host";
import firebase from 'firebase/app'
import 'firebase/messaging';
import React from "react";

var firebaseConfig = {
    apiKey: "AIzaSyAHxQebxqAp9tjqOM8WG0hgOioX1tPi8P4",
    authDomain: "belanja-pasti.firebaseapp.com",
    serverKey: "AAAAb00isj0:APA91bFef3VUpMw4pydsD4tR0CQTyCefyZ_Pk6v97ULyB2MtAcNr7nuLI4r2Dh594htF_WUZvI58oICAeswC5UNaAQWKFfX7gbo8aX6u8cM4hm96LG2s70LpNMBKNboAep9N1Bq0MGFKfO_IAQoi3pHNGavIhwoRug",
    databaseURL: "https://belanja-pasti.firebaseio.com",
    projectId: "belanja-pasti",
    storageBucket: "belanja-pasti.appspot.com",
    messagingSenderId: "478035489341",
    appId: "1:478035489341:web:acd5342518de6bcf",
    wesCertificates: "BDufqD4b8t6FwMppT_AQjXkm3P6pvEMRwlR3wkb8ZFGEjzxuOgGe6yo946JNJUSYmRT4cST3K2lrWkWX-uPspto"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

var chatTopic = '';
var merchantTopic = '';

(()=>{
    if (MAIN_HOST === 'http://103.107.85.79:8501/'){
        chatTopic = 'devel-cms-chat'
        merchantTopic = 'devel-merchant-chat'
    }else if (MAIN_HOST === 'http://localhost:8000/'){
        chatTopic = 'devel-cms-chat'
        merchantTopic = 'devel-merchant-chat'
    }else if(MAIN_HOST === 'https://belanjapasti.com/'){
        chatTopic = 'cms-chat'
        merchantTopic = 'merchant-chat'
    }
})()


export let requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
        messaging
            .requestPermission()
            .then(() => messaging.getToken())
            .then((firebaseToken) => {
                subscriberToTopic(firebaseToken, chatTopic);
                subscriberToTopic(firebaseToken, merchantTopic);
                resolve(firebaseToken);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });

export const subscriberToTopic = (token, topic) =>{
    fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'key='+firebaseConfig.serverKey
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw 'Error subscribing to topic: '+response.status + ' - ' + response.body;
        }
        console.log('Subscribed to "'+topic+'"');
    }).catch(error => {
        console.error(error);
    })
}
