import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';


import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './View/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';

const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component />
            </HashRouter>
        </Provider>,
        rootElement
    );
};

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("firebase-messaging-sw.js")
        .then(function(registration) {
            console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function(err) {
            console.log("Service worker registration failed, error:", err);
        });
}

renderApp(Main);

if (module.hot) {
    module.hot.accept('./View/Main', () => {
        const NextApp = require('./View/Main').default;
        renderApp(NextApp);
    });
}

